//LIBRARIES
import React, { useState } from "react";
import { TileBlock } from "components/UI/Common/LandingPage";
import { TileSection } from "components/UI/Common/LandingPage";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Col, Container, Icon } from "../style";
import Detecting from "./Detecting";
import { StaticImage } from "gatsby-plugin-image";
import { Parallax } from 'react-scroll-parallax';
import { useBreakpoint } from "gatsby-plugin-breakpoints";


function DatasetSection() {
  // const { ...other } = props;
  const { t } = useTranslation();
  const content = t("label_section", { returnObjects: true });
  const [detecting, setDetecting] = useState(true);
  const breakpoints = useBreakpoint();
  const tiles = content.tiles;
  
  let MAIN_COLOR = "#6c5cfa";

  const gatsbyImages = {

    abstractBackground: (
      // <Parallax translateY={[-20, 40]} style={{ width: "100%", height: "100%" }}>
      <StaticImage
        src="../../../../../assets/images/tiles/abstract-background-16.jpeg"
        alt="A Robot"
        placeholder="blurred"
        style={{
          width: "100%",
          height: "100%",
          // opacity: 0.4,
          filter: "brightness(0.6)"
        }}
      />
      // </Parallax>
    ),
    abstractBackground2: (
      // <Parallax translateY={[-20, 40]} style={{ width: "100%", height: "100%" }}>
      <StaticImage
        src="../../../../../assets/images/tiles/abstract-background-17.jpeg"
        alt="A Robot"
        placeholder="blurred"
        style={{
          width: "100%",
          height: "100%",
          // opacity: 0.4,
          filter: "brightness(0.6)"
        }}
      />
      // </Parallax>
    ),

    hands: (
      <Parallax scale={[1.2, 1]} translateY={[-10, 10]}>
        <StaticImage
          src="../../../../../assets/images/tiles/hands.jpg"
          alt="A Robot"
          placeholder="blurred"
          style={{ width: "110%", left: "-5%" }}
        />
      </Parallax>
    ),
    einstein: (
      <StaticImage
      src="../../../../../assets/images/tiles/einstein.jpg"
        alt="A Robot"
        placeholder="blurred"
      />
    ),
    einsteinMobile: (
      <StaticImage
      src="../../../../../assets/images/tiles/einstein-mobile.jpg"
        alt="A Robot"
        placeholder="blurred"
      />
    ),
    autolabeler: (
      <Parallax onProgressChange={(progress) => progress > 0.45 && setDetecting(false)}>
        <Detecting detecting={detecting} />
        <StaticImage
           src="../../../../../assets/images/tiles/autolabeler.jpg"
          alt="A Robot"
          placeholder="blurred"
          style={{ border: detecting?"5px solid rgba(10, 0, 40, 0.1)":"none"}}
        />
      </Parallax>
    ),
    autolabelerMobile: (
      <Parallax onProgressChange={(progress) => progress > 0.45 && setDetecting(false)}>
        <Detecting detecting={detecting} />
        <StaticImage
           src="../../../../../assets/images/tiles/autolabeler-mobile.jpg"
          alt="A Robot"
          placeholder="blurred"
          style={{ border: detecting?"5px solid rgba(10, 0, 40, 0.1)":"none"}}
        />
      </Parallax>
    ),
    dataset: (
      <StaticImage
        // src="../../../../../assets/images/dataset.png"
        src="../../../../../assets/images/tiles/dataset.jpg"
        alt="A Robot"
        placeholder="blurred"
      />
    ),
    datasetMobile: (
      <StaticImage
        // src="../../../../../assets/images/dataset.png"
        src="../../../../../assets/images/tiles/dataset-mobile.jpg"
        alt="A Robot"
        placeholder="blurred"
      />
    ),
  };

  
  const blocks = [
    {
      title: tiles?.einstein?.title,
      variant: "image",
      breakpoints: {
        lg: 8,
        md: 8,
        sm: 6,
        xs: 12,
      },
      withBoxShadow: true,
      image: breakpoints.isMobile ? gatsbyImages.einsteinMobile:gatsbyImages.einstein,
      titleColor: MAIN_COLOR,
      description:  tiles.einstein.description,
      imagePosition: { vertical: "bottom", horizontal: "right" }
    },
    {
      title: tiles?.hands?.title,
      description: tiles?.hands?.description,
      variant: "image",
      titleColor: MAIN_COLOR,
      // background: "linear-gradient(to bottom right, #ff52f9, #8b21fc)",
      breakpoints: {
        lg: 4,
        md: 4,
        sm: 6,
        xs: 12,
      },
      image: breakpoints.isMobile ? gatsbyImages.hands : <Parallax scale={[1.2, 1]} translateY={[-10, 10]}>{gatsbyImages.hands}</Parallax>,
      imagePosition: { vertical: "center" }
    },
    {
      // title: "Import labels from any format",
      title: tiles?.formats?.title,
      variant: "quote",
      // titleColor: "black",
      allFontsWhite: true,
      // background: "linear-gradient(145deg, rgba(134,123,254,1) 0%, rgba(123,176,254,1) 100%)",
      backgroundImage: gatsbyImages.abstractBackground,
      breakpoints: {
        lg: 4,
        md: 4,
        sm: 6,
        xs: 12,
      },
      icon: <Icon name="gallery" />,
      // quote:"You can just upload your already labeled dataset in json, xml or txt formats.",
      quote: tiles?.formats?.quote,
    },
    {
      title: tiles?.datasets?.title,
      titleColor: MAIN_COLOR,
      variant: "image",
      breakpoints: {
        lg: 8,
        md: 8,
        sm: 6,
        xs: 12,
      },
      withBoxShadow: true,
      image:breakpoints.isMobile ? gatsbyImages.datasetMobile:gatsbyImages.dataset,
      description: tiles?.datasets?.description,
      imagePosition: { vertical: "center", horizontal: "right" }
    },
    {
      title: tiles?.autolabeler?.title,
      titleColor: MAIN_COLOR,
      variant: "image",
      // background: "linear-gradient(90deg, #4f58ff 0%, #ff52f9 100%)",
      description: tiles?.autolabeler?.description,
      allFontsWhite: detecting && true,
      background: detecting && 'rgba(10, 0, 40, 0.9)',
      withBoxShadow: true,
      breakpoints: {
        lg: 8,
        md: 8,
        sm: 12,
        xs: 12,
      },
      image: breakpoints.isMobile ? gatsbyImages.autolabelerMobile:gatsbyImages.autolabeler,
      imagePosition: { vertical: "center", horizontal: "left" }
      // quote: "100x faster labeling fueled by AI magic",
      // imagePosition: { vertical: "center"}
      // imagePosition: { horizontal: "left"}

    },
    {
      title: tiles?.health?.title,
      backgroundImage: gatsbyImages.abstractBackground2,
      allFontsWhite: true,
      // titleColor: "black",
      variant: "quote",
      breakpoints: {
        lg: 4,
        md: 4,
        sm: 12,
        xs: 12,
      },
      icon: <Icon name="pie-chart" />,
      quote: tiles?.health?.quote,
      description: tiles?.health?.description,
    }
  ];

  return (
    <Container id="label">
      <TileSection
        // title={'Label'}
        title={content.title}
        
        titleColor={MAIN_COLOR}
      // backgroundImage={<StaticImage
      //   src="../../../../assets/images/stocks/neumo.jpeg"
      //   alt="A Robot"
      //   placeholder="blurred"
      //   style={{ width: "100%", height: "100%" }}
      // />}
      >
        {blocks.map((block, index) => (
          <Col
            lg={block.breakpoints.lg}
            md={block.breakpoints.md}
            sm={block.breakpoints.sm}
            xs={block.breakpoints.xs}
            key={index}
          >
            <TileBlock
              variant={block.variant}
              backgroundImage={block.backgroundImage}
              background={block.background}
              allFontsWhite={block.allFontsWhite}
              title={block.title}
              titleColor={block.titleColor}
              description={block.description}
              quote={block.quote}
              icon={block.icon}
              image={block.image}
              imagePosition={block.imagePosition}
              withBoxShadow={block.withBoxShadow}
              style={block.style}

            />
          </Col>
        ))}
      </TileSection>
    </Container>
  );
}

export default DatasetSection;
