import React, { useRef, useState, useEffect } from "react";
import { bytesToSize } from "utils";
import { timeDifference } from "utils/dates";
//STYLEDCOMPONENTS
import * as S from "./style";


function MachineCard(props) {
  const {
    selected,
    id,
    name,
    os,
    cpu,
    gpus,
    available,
    usedStorage,
    totalStorage,
    onClick,
    lastTimeOnline,
    style,
  } = props;
  const [dimensions, setDimensions] = useState(null);
  const ref = useRef(null);
  let gpusList = [];


  Object.keys(gpus).forEach((gpu, index) => {
    if (index > 0)
      gpusList = [...gpusList, `, ${gpus[gpu]}x ${gpu}`]
    else
      gpusList = [...gpusList, `${gpus[gpu]}x ${gpu}`]
  })

  let icon;

  switch (os) {
    case 'macos':
      icon = 'apple';
      break;
    case 'windows':
      icon = 'windows';
      break;
    default:
      icon = 'linux';
  }

  useEffect(() => {
    if (!dimensions)
      setDimensions({
        h: ref.current.clientHeight,
        w: ref.current.clientWidth,
      });
    function handleResize(event) {
      setDimensions({
        h: ref.current.clientHeight,
        w: ref.current.clientWidth,
      });
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [dimensions]);

  function handleOnClick(event) {
    onClick && onClick(event, id);
  }

  let dateNow = Date.now();

  return (
    <S.Container ref={ref} onClick={handleOnClick} selected={selected} style={style}>
      {dimensions != null &&
        <S.ParallaxCard width={dimensions.w} height={dimensions.h} selected={selected} >
          <S.Machine selected={selected}>
            <S.Title>{name}</S.Title>
            <S.LastTimeOnlineContainer>
              <S.StatusDot available={available} />
              {available? <S.SecondaryInfo>Connected</S.SecondaryInfo>
              :<S.SecondaryInfo>Disconnected {timeDifference(dateNow, Date.parse(lastTimeOnline))}</S.SecondaryInfo>}
            </S.LastTimeOnlineContainer>
            <S.HorizontalFlexbox>
              <S.Icon name={icon} />
              <S.Storage>
                <div className={"info"}>
                  {bytesToSize(usedStorage)} of {bytesToSize(totalStorage)} used
                </div>
                <S.RadialProgress
                  points={(usedStorage * 100) / totalStorage}
                  threshold={100}
                />
              </S.Storage>
            </S.HorizontalFlexbox>
            <S.Divider />
            <S.Info>{cpu} CPU</S.Info>
            {gpusList.length > 0 && <S.Info>{gpusList} GPU</S.Info>}
          </S.Machine>
        </S.ParallaxCard>
      }
    </S.Container>
  );
}

export default MachineCard;
