//LIBRARIES
import React from 'react';
//STYLEDCOMPONENTS
import * as S from "./style";

  
function Detecting(props) {
  const { children, name, detecting, ...other } = props;
  var isSafari = false;
  
  if(typeof window !== 'undefined'){
    isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
               navigator.userAgent &&
               navigator.userAgent.indexOf('CriOS') == -1 &&
               navigator.userAgent.indexOf('FxiOS') == -1;
  }
  
  return (
    <S.LoadingContainer detecting={detecting} isSafari={isSafari}>
    <S.WandIcon />
    <div className="loading">
      <div className="loading-text">
        <span className="loading-text-words">D</span>
        <span className="loading-text-words">E</span>
        <span className="loading-text-words">T</span>
        <span className="loading-text-words">E</span>
        <span className="loading-text-words">C</span>
        <span className="loading-text-words">T</span>
        <span className="loading-text-words">I</span>
        <span className="loading-text-words">N</span>
        <span className="loading-text-words">G</span>
      </div>
    </div>
  </S.LoadingContainer>
  );
}
 

export default Detecting;