import styled from "styled-components";
import { Container as BaseContainer } from "components/UI/Common/LandingPage";
import { Button as BaseButton } from "components/UI";


export const BackContainer = styled(BaseContainer)`
  /* position: relative; */
  /* position: absolute; */
  pointer-events: auto;
  position: relative;
  padding: 8rem 0px;
  max-height: 926px;
  @media (max-width: 720px) {
    padding: 4rem 0px;
  }
`;

export const OverflowContainer = styled.div`
width:100vw;
overflow-x: hidden;
pointer-events: none;
padding-left:25px;
padding-right:25px;


/* height: 860px; */
`;

export const Content = styled.p`
  margin-top: 1.5rem;
`;

export const Container = styled.div`
  position: relative;
  padding: 100px 0px;
  display: grid;
  row-gap: 32px;
  justify-content: center;
  align-items: flex-start;

  @media (min-width: 720px) {
    grid-template-columns: repeat(2, 1fr);
    /* grid-template-columns: 60% 40%; */
  }
`;

export const MainSection = styled.section`
  display: grid;
  row-gap: 32px;
  letter-spacing: 0.2px;
  max-width:95vw;
  z-index:1;
`;

export const Title = styled.h1`
  /* font-size: ${(props) => props.theme.fonts.title.xl.size}; */
  /* min-width: calc(100% + 100px); */
  min-width: 100%;

  @media (min-width: 720px) {
    min-width: calc(100% + 100px);
  }

  /* font-size: 60px !important; */
  font-size: 60px !important;

  @media (max-width: 720px) {
    font-size: 40px !important;
  }
  
  line-height: 1.2;
  font-weight: 300;
  letter-spacing: 0.2em;
  text-transform: uppercase;
`;

export const Body = styled.div`
  color: ${(props) => props.theme.colors.text.default};
  font-weight: 400;
  font-size: 25px;

  @media (max-width: 720px) {
    font-size: 22px;
  }
`;

export const Footer = styled.footer`
  @media (min-width: 600px) {
    padding: 0 16px 0 0;
  }

`;


export const GetStartedButton = styled(BaseButton).attrs((props) => ({
  variant: "contained",
  color: "primary",
}))`
  height: 46px;
  font-size: 18px;
  border-radius:13px;
  margin-right: 20px;
  margin-bottom: 20px;
 
  @media (max-width: 600px) {
    margin-right: 16px;
}
`;

export const ExploreButton = styled(BaseButton).attrs((props) => ({
  variant: "contained",
  color: "secondary",
}))`
  height: 46px;
  font-size: 18px;
  border-radius:13px;
  margin-bottom: 20px;
  
  div {
    display: flex;
    align-items: center;
  }
`;


export const Shadow = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  /* border-radius:50%; */
  top: 0;
  left: 0;
  /* box-shadow: inset 0px 0px 15px 10px
    blue; */
 
  box-shadow: inset 0px 0px 15px 10px
    ${(props) => props.theme.colors.button.default};
`;

export const VideoContainer = styled.div`
  height: 100%;
  top: 0;
  right: 0;
  position: absolute;
  filter: saturate(2);
  z-index:1;
  
  /* clip-path: circle(50% at 50% 50%); */
  /* -webkit-mask-image: radial-gradient(ellipse 90% 80% at 48% 78%, black 40%, transparent 50%); */
  mask-image: radial-gradient(ellipse 90% 80% at 48% 78%, black 40%, transparent 50%);
  
  mask-image: radial-gradient(circle 500px at center,  black 75%, transparent 85%);


  /* clip-path: circle(50.0% at 50% 50%); */

  transform: translate(30%);
  -ms-transform:translate(30%); /* IE 9 */
  -moz-transform:translate(30%); /* Firefox */
  -webkit-transform:translate(30%); /* Safari and Chrome */
  -o-transform:translate(30%); 

  @media (max-width: 1012px) {
    transform: translate(50%);
    transform: translate(50%);
  -ms-transform:translate(50%); /* IE 9 */
  -moz-transform:translate(50%); /* Firefox */
  -webkit-transform:translate(50%); /* Safari and Chrome */
  -o-transform:translate(50%); 
    opacity:0.7;
  }

  @media (max-width: 480px) {
    transform: translate(70%,-5%);
  -ms-transform:translate(70%,-5%); /* IE 9 */
  -moz-transform:translate(70%,-5%); /* Firefox */
  -webkit-transform:translate(70%,-5%); /* Safari and Chrome */
  -o-transform:translate(70%,-5%); 
    opacity:0.7;
  }

  > .wrapper {
   position: relative;
   width:100%;
   height:100%;
  }

  video {

    height: 100%;
    aspect-ratio: 1 / 1;
    position: absolute;
    top:0;
    left:0;
  }

  .img {
    height: 100%;
    aspect-ratio: 1 / 1;
  }
  /* box-shadow: inset 0px 0px 10px 5px
    ${(props) => props.theme.colors.button.default}; */

  /* box-shadow:
  0 100px 80px rgb(0 0 0 / 2%),
  0 41.7776px 33.4221px rgb(0 0 0 / 2%),
  0 22.3363px 17.869px rgb(0 0 0 / 3%),
  0 12.5216px 10.0172px rgb(0 0 0 / 3%),
  0 6.6501px 5.32008px rgb(0 0 0 / 4%),
  0 2.76726px 2.21381px rgb(0 0 0 / 7%);
  box-shadow: 5px 5px 5px 5px blue; */
  div {
    /* height: 100%; */
  }
  img {
    height: 100%;
  }



`;
