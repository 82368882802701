import styled from "styled-components";
import { Grid } from "@mui/material";


export const Title = styled.div`
  /* font-size: 60px;
  line-height: 64px; */
   
  font-size: 56px;
  line-height: 1.0714285714;
  padding-bottom: 5px;
 

  color: ${(props) => props.theme.colors.text.default};
  /* font-weight: ${(props) => props.theme.fonts.weight.bold}; */
  font-weight:600;

  background: linear-gradient(90deg, #636bff 0%, #D058BB 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  /* @media (min-width: 740px) and (max-width: 1400px) {
    font-size: 45px;
  }

  @media (min-width: 740px) and (max-width: 1100px) {
    font-size: 29px;
  }

  @media (max-width: 740px) {
    font-size: 45px;
  } */

  @media (min-width: ${(props) => props.theme.breakpoints.sm}px) and (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    font-size: 45px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}px) and (max-width: ${(props) => props.theme.breakpoints.md}px) {
    font-size: 29px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    font-size: 40px;
  }



`;

export const Subtitle = styled.div`
  margin-top:16px;
  font-size: 28px;

  /* @media (min-width: 740px) and (max-width: 1400px) {
    font-size: 23px;
  }

  @media (min-width: 740px) and (max-width: 1100px) {
    font-size: 17px;
  }

  @media (max-width: 740px) {
    font-size: 23px;
    padding-bottom: 32px;
  } */

  @media (min-width: ${(props) => props.theme.breakpoints.sm}px) and (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    font-size: 23px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}px) and (max-width: ${(props) => props.theme.breakpoints.md}px) {
    font-size: 17px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    font-size: 23px;
    padding-bottom: 32px;
  }




  line-height: 1.0714285714;
  color: ${(props) => props.theme.colors.text.default};
  font-weight:400; 
`;



export const Row = styled(Grid).attrs({
  direction: "row",
  container: true,
  justifyContent: "center",
})`
  padding: 0px 16px;
`;

export const Col = styled(Grid).attrs({
  item: true,
  container: true,
  direction: "column",
})``;

export const TitleContainer = styled.div`
    /* max-width: 650px; */
    max-width: ${(props) => (props.scale ? 565 * props.scale : 565)}px;
    will-change: transform, opacity;
    /* position: absolute; */
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: flex-start;
    -webkit-transform-origin: center;
    transform-origin: center;
    pointer-events: none;
    will-change: auto;
    z-index: 4;
    opacity: 1;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    width: 100%;
    height: 100%;
    padding: 20px;
    /* width: 2820px;
    height: 2350px; */
`;

export const Container = styled.section`
  position: relative;
  z-index: 0;
  width: 100%;
  max-width: calc(1440px - 25px * 2);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  width: 100vw;
  min-height: calc(1440px - 60px);
  height: fit-content;

  /* @media (min-width: 740px) and (max-width: 1400px) {
      min-height: 800px;
      transform: translateY(-200px);
      
  }

  @media (min-width: 740px) and (max-width: 1100px) {
    min-height: 600px;
    transform: translateY(-300px);
  }


  @media (max-width: 740px) {
    height: 900px;
      flex-direction: column;
      transform: translateY(-100px);
  } */

  @media (min-width: ${(props) => props.theme.breakpoints.sm}px) and (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    min-height: 800px;
    transform: translateY(-200px);
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}px) and (max-width: ${(props) => props.theme.breakpoints.md}px) {
    min-height: 600px;
    transform: translateY(-300px);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    height: 900px;
    flex-direction: column;
    transform: translateY(-100px);
  }

`;



export const MobileCardsContainer = styled.section`
display: flex;
flex-direction: column;
position: relative;

> * {
  margin-bottom: 32px;
  height: 180px;
  max-width: 330px;
}
`;

export const CardsContainer = styled.section`
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    transform: translateX(20px);
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}px) {
  > {
    .story-1 {
      position: absolute;
    
      transform: ${(props) => (props.scale ? `translate(-${props.scale * 150}px, -${(props.scale * 250)}px)` : `translate(-150px, -250px)`)};

      width: ${(props) => (props.scale ? 300 * props.scale : 300)}px;
      height: ${(props) => (props.scale ? 200 * props.scale : 200)}px;
    }
    .story-2 {
      position: absolute;
    
      transform: ${(props) => (props.scale ? `translate(${props.scale * 440}px, ${(props.scale * 18)}px)` : `translate(440px, 18px)`)};
   
      width: ${(props) => (props.scale ? 250 * props.scale : 250)}px;
      height: ${(props) => (props.scale ? 287 * props.scale : 287)}px;
    }
    .story-3 {
      position: absolute;
 
      transform: ${(props) => (props.scale ? `translate(${props.scale * 210}px, -${(props.scale * 260)}px)` : `translate(210px, -260px)`)};
    
      width: ${(props) => (props.scale ? 320 * props.scale : 320)}px;
      height: ${(props) => (props.scale ? 200 * props.scale : 200)}px;
    }
    .story-4 {
      position: absolute;
    
      transform: ${(props) => (props.scale ? `translate(-${props.scale * 470}px, ${(props.scale * 18)}px)` : `translate(-470px, 18px)`)};
    
      width: ${(props) => (props.scale ? 260 * props.scale : 260)}px;
      height: ${(props) => (props.scale ? 300 * props.scale : 300)}px;
    }
    .story-5 {
      position: absolute;
  
      transform: ${(props) => (props.scale ? `translate(${props.scale * 130}px, ${(props.scale * 285)}px)` : `translate(130px, 285px)`)};

      width: ${(props) => (props.scale ? 300 * props.scale : 300)}px;
      height: ${(props) => (props.scale ? 220 * props.scale : 220)}px;
    }
    .story-6 {
      position: absolute;
     
      transform: ${(props) => (props.scale ? `translate(-${props.scale * 170}px, ${(props.scale * 310)}px)` : `translate(-170px, 310px)`)};
 
      width: ${(props) => (props.scale ? 250 * props.scale : 250)}px;
      height: ${(props) => (props.scale ? 300 * props.scale : 300)}px;
    }
  }

}
`;

