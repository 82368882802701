import styled from "styled-components";
import { Grid } from "@mui/material";
import { Button as BaseButton } from "components/UI";


export const ContactContainer = styled.div`
  margin-top:200px;
  margin-bottom:50px;

  @media only screen and (max-width: 1024px) {
    padding: 3rem 0;
  }
`;


export const ContactButton = styled(BaseButton).attrs((props) => ({
  variant: "contained",
  color: "primary",
}))`
  height: 46px;
  font-size: 18px;
  border-radius:13px;
  margin-top: 20px;
  padding:0px 40px;
`;




export const FormGroup = styled.form`
  width: 100%;
  max-width: 520px;

  @media only screen and (max-width: 1045px) {
    max-width: 100%;
    margin-top: 2rem;
  }
`;

export const Span = styled.span`
  display: block;
  font-weight: 600;
  color: rgb(255, 130, 92);
  height: 0.775rem;
  padding: 0 0.675rem;
`;

export const ButtonContainer = styled.div`
  text-align: end;
  position: relative;

  @media only screen and (max-width: 414px) {
    padding-top: 0.75rem;
  }
`;

export const Row = styled(Grid).attrs(({
  direction:"row",
  container:true,
  justify:"space-between"
}))`     
`;

export const Col = styled(Grid).attrs(({
  item:true,
  container: true,
  direction:"column" 
}))`     
`;

