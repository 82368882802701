//LIBRARIES
import React, { useState, useEffect, useRef } from "react";

//STYLEDCOMPONENTS
import {
  StyledContainer as Container,
  StyledTitle as Title,
  StyledDescription as Description,
  StyledBackdropFilterImgContainer as BackdropFilterImgContainer,
  StyledContent as Content,
  StyledCard as CardBase,
  StyledBlurriedImageContainer as BlurriedImageContainer,
  StyledImageContainer as ImageContainer,
  StyledLearnMoreContainer as LearnMoreContainer
} from "./style";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { useTranslation } from "gatsby-plugin-react-i18next";

export default function Card(props) {
  const {
    img,
    imgOriginal,
    comingSoon,
    title,
    description,
    lowResolutionImg,
    detected,
  } = props;
  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  const breakpoints = useBreakpoint();
  const { t } = useTranslation();

  useEffect(() => {
    setHeight(ref.current.clientHeight)
  });

  return (
    <Container ref={ref} comingSoon={comingSoon}>
      <BlurriedImageContainer>{lowResolutionImg}</BlurriedImageContainer>
      <CardBase>
        <ImageContainer detected={true}>{img}</ImageContainer>
        <ImageContainer detected={!detected}> {imgOriginal}</ImageContainer>
        {breakpoints.isMobile &&
          <LearnMoreContainer>
            {t("Learn more")}
          </LearnMoreContainer>
        }
        <BackdropFilterImgContainer height={height}>
          {lowResolutionImg}
        </BackdropFilterImgContainer>
        <Content height={height}>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </Content>
      </CardBase>
    </Container>
  );
}
