import React from "react";
import * as S from "./style";
import Hero from "./Hero";
import Contact from "./Contact";
import MainLayout from "components/MainLayout";
import StoriesSection from "./StoriesSection";
import StartYourJourney from "./StartYourJourney";
import Sections from "./Sections";
import { Container } from "components/UI/Common/LandingPage";


const Home = () => {
  return (
    <MainLayout>
      <S.Container>
        <Hero />
        <StartYourJourney />
        <StoriesSection />
        <Sections />
        <Container>
          <Contact />
        </Container>
      </S.Container>
    </MainLayout>
  );
};

export default Home;
