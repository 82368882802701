//LIBRARIES
import React from "react";
import { TileBlock } from "components/UI/Common/LandingPage";
import { TileSection } from "components/UI/Common/LandingPage";
import { useTranslation } from "gatsby-plugin-react-i18next";
import {Col, Container, Icon} from "../style";
import { StaticImage } from "gatsby-plugin-image";
import { useBreakpoint } from "gatsby-plugin-breakpoints";


function DeploymentSection() {
  // const { ...other } = props;
  const { t } = useTranslation();
  const content = t("deploy_section", { returnObjects: true });
  const tiles = content.tiles;
  const breakpoints = useBreakpoint();

  // let MAIN_COLOR = "#00c2a1";
  let MAIN_COLOR = "#009e9e";

  const gatsbyImages = {
    deployment: (
      <StaticImage
          src="../../../../../assets/images/tiles/deployment.jpg"
          alt="A Robot"
          placeholder="blurred"
          style={{ width: "100%", height: "100%" }}
        />
    ),
    deploymentMobile: (
      <StaticImage
          src="../../../../../assets/images/tiles/deployment-mobile.jpg"
          alt="A Robot"
          placeholder="blurred"
          style={{ width: "100%", height: "100%" }}
        />
    ),
    playground: (
      <StaticImage
          src="../../../../../assets/images/tiles/playground.jpg"
          alt="A Robot"
          placeholder="blurred"
          style={{ width: "100%", height: "100%" }}
        />
    ),
    playgroundMobile: (
      <StaticImage
          src="../../../../../assets/images/tiles/playground-mobile.jpg"
          alt="A Robot"
          placeholder="blurred"
          style={{ width: "100%", height: "100%" }}
        />
    ),
    abstractBackground: (
      <StaticImage
        src="../../../../../assets/images/tiles/abstract-background-18.jpeg"
        alt="A Robot"
        placeholder="blurred"
        style={{ width: "100%", height: "100%", filter: "brightness(0.6)" }}
      />
    ),
  };


  const blocks = [
    {
      title: tiles?.deployment?.title,
      titleColor: MAIN_COLOR,
      variant: "image",
      breakpoints: {
        lg: 8,
        md: 8,
        sm: 6,
        xs: 12,
      },
      image: breakpoints.isMobile? gatsbyImages.deploymentMobile:gatsbyImages.deployment,
      withBoxShadow: true,
      description:  tiles?.deployment?.description,
      imagePosition: { vertical: "bottom", horizontal: "right" }
    },
    {
      title:  tiles?.rocket?.title,
      // titleColor: MAIN_COLOR,
      backgroundImage: gatsbyImages.abstractBackground,
      variant: "quote",
      breakpoints: {
        lg: 4,
        md: 4,
        sm: 6,
        xs: 12,
      },
      icon: <Icon name={"rocket"} />,
     allFontsWhite: true,
      withBoxShadow: true,
      quote: tiles.rocket.quote,
      imagePosition: { vertical: "center", horizontal: "center" }
    },
    {
      title: tiles?.playground?.title,
      titleColor: MAIN_COLOR,
      withBoxShadow: true,
      variant: "image",
      breakpoints: {
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12,
      },
      style: { minHeight: "300px" },
      image: breakpoints.isMobile? gatsbyImages.playgroundMobile:gatsbyImages.playground,
      description: tiles?.playground?.description,
      imagePosition: { vertical: "bottom", horizontal: breakpoints.isMobile?"left":"center" }
    }
  ];

  return (
    <Container id="deploy">
      <TileSection
        // title={'Deploy'}
        
             title={content.title}
        titleColor={MAIN_COLOR}
        // backgroundImage={<StaticImage
        //   src="../../../../assets/images/stocks/neumo.jpeg"
        //   alt="A Robot"
        //   placeholder="blurred"
        //   style={{ width: "100%", height: "100%" }}
        // />}
      >
        {blocks.map((block, index) => (
          <Col
            lg={block.breakpoints.lg}
            md={block.breakpoints.md}
            sm={block.breakpoints.sm}
            xs={block.breakpoints.xs}
            key={index}
          >
            <TileBlock
              variant={block.variant}
              backgroundImage={block.backgroundImage}
              background={block.background}
              allFontsWhite={block.allFontsWhite}
              title={block.title}
              titleColor={block.titleColor}
              description={block.description}
              quote={block.quote}
              icon={block.icon}
              withBoxShadow={block.withBoxShadow}
              image={block.image}
              imagePosition={block.imagePosition}
              style={block.style}
            />
          </Col>
        ))}
      </TileSection>
    </Container>
  );
}

export default DeploymentSection;
