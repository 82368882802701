import styled from "styled-components";

// function getValueByProgress(startPercentage, progress, finalValue) {
//   console.log(
//     progress - startPercentage < 0
//       ? 0
//       : ((progress - startPercentage) * finalValue) / (1 - startPercentage)
//   );
//   return progress - startPercentage < 0
//     ? 0
//     : ((progress - startPercentage) * finalValue) / (1 - startPercentage);
// }

export const Container = styled.section`
  position: relative;
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(-100px);


    z-index:0;
    @supports (-webkit-touch-callout: none) {
      z-index:-1;
}

@supports not (-webkit-touch-callout: none) {
  z-index:0;
}

    -moz-user-select: none;
-webkit-user-select: none;
user-select: none;
pointer-events: none;

  @media (max-width: 720px) {
    transform: translateY(-120px);
  }
  @media (max-width: 470px) {
    transform: translateY(-400px);
  }

  /* .gatsby-image-wrapper {
   position: relative;
   width: 100%;
   height: 100%;
   z-index: 0; 
} */

  /* .buildings {
    mask-image: linear-gradient(to top, transparent 10%, black 50%);
  } */
`;

export const Background = styled.div`
  transform: translateY(-200px);
  width: 100%;
  opacity: 0.4;
  background-color: blue;

  /* filter: blur(50px); */
  filter: blur(45px);
  .background {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    transform: translate(-50%, 10%);
    height: 1200px;

    /* background: linear-gradient(
      145deg,
      rgba(134, 123, 254, 1) 0%,
      rgba(123, 176, 254, 1) 100%
    ); */
    /* background: linear-gradient(
      145deg,
      rgba(205, 103, 255, 1) 0%,
      rgba(123, 242, 254, 1) 100%
    ); */

    /* background: linear-gradient(
      145deg,
      rgba(205, 103, 255, 1) 0%,
      rgba(123, 242, 254, 1) 100%
    );

    background: rgb(205,103,255);
background: -moz-linear-gradient(145deg, rgba(205,103,255,1) 0%, rgba(123,242,254,1) 100%);
background: -webkit-linear-gradient(145deg, rgba(205,103,255,1) 0%, rgba(123,242,254,1) 100%);
background: linear-gradient(145deg, rgba(205,103,255,1) 0%, rgba(123,242,254,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#cd67ff",endColorstr="#7bf2fe",GradientType=1); */

background: rgb(205,103,255);
background: -moz-linear-gradient(145deg, rgba(205,103,255,1) 0%, rgba(123,242,254,1) 100%);
background: -webkit-linear-gradient(145deg, rgba(205,103,255,1) 0%, rgba(123,242,254,1) 100%);
background: linear-gradient(145deg, rgba(205,103,255,1) 0%, rgba(123,242,254,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#cd67ff",endColorstr="#7bf2fe",GradientType=1);


    clip-path: polygon(
      36% 24%,
      61% 21%,
      85% 20%,
      100% 9%,
      100% 60%,
      0 83%,
      0 14%,
      10% 21%,
      19% 28%
    );

  }
`;

// export const Title = styled.div`
//   height: 400px;
//   text-align: center;
//   font-size: 56px;
//   line-height: 1.0714285714;
//   z-index: 1;
//   color: ${(props) => props.theme.colors.text.default};
//   font-weight: 600;
//   background: linear-gradient(90deg, #636bff 0%, #d058bb 100%);
//   -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
//   animation: transform 300ms;
// `;

// export const LottieContainer = styled.div`
//   position: relative;
//   height: 200px;
//   z-index: 10;
// `;


// const waves = keyframes`
//     0% {
//     margin-left: 0;
//   }
//   100% {
//     margin-left: -1600px;
//   }
// `;

// const swell = keyframes`
//    0%, 100% {
//     transform: translate3d(0,-25px,0);
//   }
//   50% {
//     transform: translate3d(0,5px,0);
//   }
// `;

// export const WavesContainer = styled.div`
//   position: absolute;
//   height: 100%;
//   width: 100%;

//   bottom: 0;
//   left: 0;
//   filter: blur(20px);
//   opacity: 0.8;

//   &:nth-of-type(2) {
//     transform: scale(-1);
//     filter: blur(64px);
//     top: -240px;
//     opacity: 1;
//     left: 0;
//   }

//   .wave {
//     background-color: #a278ea;
//     background-color: rgba(162, 120, 234, 0.4);
//     -webkit-mask-image: url("wave.svg");
//     mask-image: url("wave.svg");
//     position: absolute;
//     color: blue;
//     top: -198px;
//     width: 6400px;
//     height: 198px;
//     animation: ${waves} 16s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
//     /* animation: ${waves} 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite; */
//     transform: translate3d(0, 0, 0);
//   }

//   .wave:nth-of-type(2) {
//     top: -145px;
//     left: -200px;
//     background-color: #fa6ccd;
//     background-color: rgba(250, 108, 205, 0.4);
//     animation: ${waves} 24s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, ${swell} 7s ease -1.25s infinite;
//     opacity: 1;
//   }

//   .wave:nth-of-type(3) {
//     top: -105px;
//     left: -300px;
//     background-color: #30b8fa;
//     background-color: rgba(48, 184, 250, 0.3);
//     animation: ${waves} 12s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, ${swell} 7s ease -1.25s infinite;
//     opacity: 1;
//   }
// `;

// export const OceanContainer = styled.div`
//   position: absolute;
//   height: 200px;
//   margin-top: 200px;
//   width: 102vw;
//   transform: rotate(-4deg);
// `;
