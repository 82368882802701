//LIBRARIES
import React from "react";
import { TileBlock } from "components/UI/Common/LandingPage";
import { TileSection } from "components/UI/Common/LandingPage";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Col, Container } from "../style";
import { StaticImage } from "gatsby-plugin-image";
import { Parallax } from "react-scroll-parallax";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import * as S from "./style";

function OutroSection() {
  // const { ...other } = props;
  const { t } = useTranslation();
  const content = t("outro_section", { returnObjects: true });
  const tiles = content.tiles;
  const breakpoints = useBreakpoint();

  let MAIN_COLOR = "#C10C74";

  const gatsbyImages = {
    robot: (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          transform: breakpoints.xs
            ? "translateX(50%) translateY(-55%) scale(2.5)"
            : breakpoints.sm
            ? "translateX(30%) translateY(-40%) scale(2)"
            : breakpoints.md
            ? "translateX(20%) translateY(-10%) scale(1.4)"
            : undefined,
        }}
      >
        <div
          style={{
            width: "100%",
          }}
        >
          <StaticImage
            src="../../../../../assets/images/tiles/robot.jpeg"
            alt="A Robot"
            placeholder="blurred"
            layout="fullWidth"
            style={{ width: "100%", height: "100%" }}
  
          />
        </div>
      </div>
    ),
  };

  const blocks = [
    {
      title: tiles?.robot?.title,
      titleColor: MAIN_COLOR,
      variant: "image",
      breakpoints: {
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12,
      },
      style: { minHeight: breakpoints.xs ? "650px" : "900px" },
      backgroundImage: breakpoints.isMobile ? (
        gatsbyImages.robot
      ) : (
        <Parallax
          translateY={[-20, 40]}
          style={{ width: "100%", height: "100%" }}
        >
          {gatsbyImages.robot}
        </Parallax>
      ),
      description: <S.DescriptionContainer>{tiles?.robot?.description}<S.RedButton onClick={() => window.location.href = 'https://app.theos.ai/authentication/signup'}>{t('Try it now for free')}</S.RedButton></S.DescriptionContainer>,
    },
  ];

  return (
    <Container id="outro">
      <TileSection
        title={content.title}
        titleColor={MAIN_COLOR}
        backgroundImage={
          <StaticImage
            src="../../../../../assets/images/stocks/neumo.jpeg"
            alt="A Robot"
            placeholder="blurred"
            style={{ width: "100%", height: "100%" }}
          />
        }
      >
        {blocks.map((block, index) => (
          <Col
            lg={block.breakpoints.lg}
            md={block.breakpoints.md}
            sm={block.breakpoints.sm}
            xs={block.breakpoints.xs}
            key={index}
          >
            <TileBlock
              variant={block.variant}
              backgroundImage={block.backgroundImage}
              background={block.background}
              allFontsWhite={block.allFontsWhite}
              title={block.title}
              titleColor={block.titleColor}
              description={block.description}
              quote={block.quote}
              lottie={block.lottie}
              image={block.image}
              imagePosition={block.imagePosition}
              style={block.style}
            />
          </Col>
        ))}
      </TileSection>
    </Container>
  );
}

export default OutroSection;
