import styled from "styled-components";
import { Grid } from "@mui/material";
import BaseIcon from "icons";

export const Icon = styled(BaseIcon).attrs({
  size: "50%"
})`
position: absolute;
left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
`;

export const Col = styled(Grid).attrs({
  item: true,
  container: true,
  direction: "column",
})``;


export const Container = styled.section`
  width: 100%;
  max-width: calc(1440px - 25px * 2);
  margin-right: auto;
  margin-left: auto;
  /* margin-bottom: 100px; */
`;

