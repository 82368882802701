import * as S from "./styles";
import React, { useState } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import SphereVideo from "assets/videos/sphere1600Medium.mp4";

const Hero = ({ title }) => {
  const { t } = useTranslation();
  const content = t("Intro", { returnObjects: true });
  const [videoLoaded, setVideoLoaded] = useState(false);

  return (
      <S.OverflowContainer>
        <S.BackContainer>
          <S.VideoContainer>
            <div className="wrapper">
              <StaticImage
                className="img"
                loading="eager"
                src="../../../../assets/images/sphere1600Medium.jpg"
                alt="AI Orb"
                placeholder="blurred"
              />
              <video
              autoPlay={true}
              loop={true}
              controls={false}
              playsInline
              muted
            >
              <source src={SphereVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            </div>

            <S.Shadow />
          </S.VideoContainer>
          <S.Container>
            <S.MainSection>
              <S.Title>{content.title}</S.Title>
              <S.Body>{content.body}</S.Body>
              <S.Footer>
                <div className={"button-container"}>
                  <S.GetStartedButton onClick={() => window.location.href = 'https://app.theos.ai/authentication/signup'}>
                    {content["Get Started"]}
                    {/* {t("Developers")} */}
                  </S.GetStartedButton>
                  <S.ExploreButton onClick={() => window.open('https://docs.theos.ai/get-started/object-detection', '_blank')}>
                    {content["Explore"]}
                  </S.ExploreButton>
                </div>
              </S.Footer>
            </S.MainSection>
            <div></div>
          </S.Container>
        </S.BackContainer>
      </S.OverflowContainer>
  );
};

export default Hero;

// export const query = graphql`
//   query($language: String!) {
//     locales: allLocale(filter: {language: {eq: $language}}) {
//       edges {
//         node {
//           ns
//           data
//           language
//         }
//       }
//     }
//   }
// `;