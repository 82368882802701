import * as React from "react";
import Home from "components/landing-page/Home";
import { graphql } from 'gatsby'

const IndexPage = ({}) => {
  return (
    <Home/>
  )
};

export default IndexPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;