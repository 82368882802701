import React, { useRef, useState, useEffect } from "react";

//STYLEDCOMPONENTS
import neumo_purple from "./neumo_purple.svg";
// import Lottie from "react-lottie";
import * as S from "./style";
import { Orb } from "components/UI";
// import blurGradient from "assets/lotties/blur_gradient.json";

function MachineCard(props) {
  const {
    selected,
    name,
    id,
    cpu,
    gpus,
    onClick,
    style,
  } = props;

  const [dimensions, setDimensions] = useState(null);
  const ref = useRef(null);
  let gpusList = [];
  // var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  var isSafari = true;

  Object.keys(gpus).forEach((gpu, index) => {
    if (index > 0) gpusList = [...gpusList, `, ${gpus[gpu]}x ${gpu}`];
    else gpusList = [...gpusList, `${gpus[gpu]}x ${gpu}`];
  });

  useEffect(() => {
    if (!dimensions)
      setDimensions({
        h: ref.current.clientHeight,
        w: ref.current.clientWidth,
      });
    function handleResize(event) {
      setDimensions({
        h: ref.current.clientHeight,
        w: ref.current.clientWidth,
      });
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [dimensions]);

  function handleOnClick(event) {
    onClick && onClick(event, id);
  }

  const blurGradientOptions = {
    loop: true,
    autoplay: true,
    // animationData: blurGradient,
    renderer: isSafari ? "canvas" : "svg"
  };

  return (
    <S.Container ref={ref} onClick={handleOnClick} clickeable={true}  selected={selected} style={style}>
      {/* <Lottie
        style={{
          bottom: isSafari ? "-110px" : "-80px",
          opacity: 0.5,
          transform: "scaleY(-1)",
          position: "absolute",
          filter: isSafari ? "blur(16px)" : "none",
        }}
        height={"auto"}
        width={"100%"}
        speed={2}
        isClickToPauseDisabled={true}
        options={blurGradientOptions}
      /> */}

      {dimensions != null && (
        <S.ParallaxCard
          width={dimensions.w}
          height={dimensions.h}
          selected={selected}
        >
          <S.Machine selected={selected}>
            <S.BackgroundSvg alt="" src={neumo_purple} />
            {/* <Lottie
              style={{
                bottom: isSafari ? "-36px" : "-10px",
                borderRadius: "100px",
                position: "absolute",
                filter: isSafari ? "blur(10px)" : "none",
              }}
              isClickToPauseDisabled={true}
              options={blurGradientOptions}
              height={"auto"}
              width={"100%"}
              speed={2}
            /> */}
            <S.Content>
              <div className="header">
                <S.Title>{name}</S.Title>
                <span>&nbsp;by&nbsp;</span>
                <S.Logo />
              </div>
              <S.LastTimeOnlineContainer>
                <S.StatusDot available={true} />
                <S.SecondaryInfo>
                  Connected
                </S.SecondaryInfo>
              </S.LastTimeOnlineContainer>
              <S.OrbContainer>
                <Orb color="purple" />
              </S.OrbContainer>
              <S.Info>{cpu} CPU</S.Info>
              {gpusList.length > 0 && <S.Info>{gpusList} GPU</S.Info>}
            </S.Content>
          </S.Machine>
        </S.ParallaxCard>
      )}
    </S.Container>
  );
}

export default MachineCard;
