//LIBRARIES
import React from "react";
import { TileBlock } from "components/UI/Common/LandingPage";
import { TileSection } from "components/UI/Common/LandingPage";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Col, Container, Icon } from "../style";
import { MachineCard } from "components/UI/Common/LandingPage/Machines";
import { TheosMachineCard } from "components/UI/Common/LandingPage/Machines";
import { StaticImage } from "gatsby-plugin-image";
import { Parallax } from "react-scroll-parallax";
import { useBreakpoint } from "gatsby-plugin-breakpoints";


function TrainingSection() {
  const { t } = useTranslation();
  const content = t("label_train", { returnObjects: true });
  const tiles = content.tiles;
  const breakpoints = useBreakpoint();

  let MAIN_COLOR = "#3a85fc";

  const gatsbyImages = {
    cloudMachine: (
      <div
        style={{
          width: "100%",
          height: "100%",
          position: breakpoints.isMobile ? "relative":"absolute",
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          paddingBottom:  breakpoints.isMobile ? "15px":'15%',
          alignItems: 'center',
          left: breakpoints.isMobile ? undefined : "50%",
          top: breakpoints.isMobile ? undefined : "50%",
          transform: breakpoints.isMobile ? undefined : "translate(-50%,-50%)"
        }}
      >
        <TheosMachineCard
          gpus={{ "Tesla T4": 1 }}
          id={1}
          name={"Tesla T4"}
          os={"linux"}
          cpu={"8x Intel Xeon 2.00GHz"}
          style={{
            width: "270px",
            height: "215px"
          }}
          available={12121212}
          usedStorage={51212121111}
          totalStorage={121212121211}
        />
      </div>
    ),
    machine: (
      <div
        style={{
          width: "100%",
          height: "100%",
          position: breakpoints.isMobile ? "relative":"absolute",
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          paddingBottom:  breakpoints.isMobile ? "15px":'15%',
          alignItems: 'center',
          left: breakpoints.isMobile ? undefined : "50%",
          top: breakpoints.isMobile ? undefined : "50%",
          transform: breakpoints.isMobile ? undefined : "translate(-50%,-50%)"
        }}
      >
        <MachineCard
          gpus={{ "Tesla K80": 1 }}
          id={1}
          name={"Google Colab"}
          os={"linux"}
          cpu={"Intel(R) Xeon(R) @ 2.20GHz"}
          style={{
            width: "270px",
            height: "215px"
          }}
          available={12121212}
          usedStorage={51212121111}
          totalStorage={121212121211}
        />
      </div>
    ),
    abstractBackground: (
      <StaticImage
        src="../../../../../assets/images/tiles/abstract-background-13.jpeg"
        alt="A Robot"
        placeholder="blurred"
        style={{ width: "100%", height: "100%", filter: "brightness(0.7)" }}
      />
    ),
    abstractBackground2: (
      <StaticImage
        src="../../../../../assets/images/tiles/abstract-background-8.jpg"
        alt="A Robot"
        placeholder="blurred"
        style={{ width: "100%", height: "100%", filter: "brightness(0.4)" }}

      />
    ),
    training: (
      <StaticImage
        src="../../../../../assets/images/tiles/training.jpg"
        alt="A Robot"
        placeholder="blurred"
        style={{ width: "100%", height: "100%" }}
      />
    ),
    trainingMobile: (
      <StaticImage
        src="../../../../../assets/images/tiles/training-mobile.jpg"
        alt="A Robot"
        placeholder="blurred"
        style={{ width: "100%", height: "100%" }}
      />
    ),
    RobotAi: (
      <StaticImage
        src="../../../../../assets/images/tiles/robot-westworld.jpg"
        alt="A Robot"
        placeholder="blurred"
        style={{ height: "100%", width: "100%" }}
      />
    ),
    RobotAiMobile: (
      <StaticImage
        src="../../../../../assets/images/tiles/robot-westworld-mobile.jpg"
        alt="A Robot"
        placeholder="blurred"
        style={{ height: "100%", width: "100%" }}
      />
    ),
    library: (
      <StaticImage
        src="../../../../../assets/images/tiles/subfields.jpg"
        alt="A Robot"
        placeholder="blurred"
        style={{ width: "130%", left: "-15%" }}
      />
    ),
    libraryMobile: (
      <StaticImage
        src="../../../../../assets/images/tiles/subfields-mobile.jpg"
        alt="A Robot"
        placeholder="blurred"
        style={{ width: "130%", left: "-15%" }}
      />
    ),
  };

  const blocks = [
    {
      title: tiles?.subfields?.title,
      variant: "image",
      breakpoints: {
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12,
      },
      titleColor: MAIN_COLOR,
      style: { minHeight: "300px" },
      image: breakpoints.isMobile ? gatsbyImages.libraryMobile : <Parallax translateX={[-15, 15]}>{gatsbyImages.library}</Parallax>,
      description: tiles?.subfields?.description,
      imagePosition: { vertical: "center" },
    },
    {
      title: tiles?.training?.title,
      variant: "image",
      withBoxShadow: true,
      breakpoints: {
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12,
      },
      titleColor: MAIN_COLOR,
      style: { minHeight: "300px" },
      image: breakpoints.isMobile ? gatsbyImages.trainingMobile : gatsbyImages.training,
      description: tiles?.training?.description,
      imagePosition: { vertical: "bottom", horizontal: "center" },
    },
    {
      variant: "quote",
      icon: <Icon name="stats" />,
      backgroundImage: gatsbyImages.abstractBackground,
      description: tiles?.stats?.description,
      quote: tiles?.stats?.quote,
      allFontsWhite: true,
      breakpoints: {
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
      },
      title: tiles?.stats?.title,
    },
    {
      title: tiles?.transferLearning?.title,
      icon: <Icon name="transfer-learning" />,
      variant: "quote",
      backgroundImage: gatsbyImages.abstractBackground2,
      allFontsWhite: true,
      breakpoints: {
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
      },
      description: tiles?.transferLearning?.description,
      quote: tiles?.transferLearning?.quote,
    },
    {
      title: tiles?.machine?.title,
      titleColor: MAIN_COLOR,
      variant: "image",
      background: "#F4F5F9",
      breakpoints: {
        lg: 6,
        md: 6,
        sm: 12,
        xs: 12,
      },
      image: gatsbyImages.machine,
      style: { 
        minHeight:
          breakpoints.xs ? "500px":
          breakpoints.md ? "750px":"625px" 
      },
      imagePosition: { vertical: "center", horizontal: "center" },
      description: tiles?.machine?.description,
    },
    {
      title: tiles?.cloudMachine?.title,
      titleColor: MAIN_COLOR,
      variant: "image",
      background: "#F4F5F9",
      breakpoints: {
        lg: 6,
        md: 6,
        sm: 12,
        xs: 12,
      },
      image: gatsbyImages.cloudMachine,
      // style: { minHeight: breakpoints.sm ? "700px" : "200px" },
      style: { minHeight: breakpoints.xs ? "440px" : "500px" },
      imagePosition: { vertical: "center", horizontal: "center" },
      description: tiles?.cloudMachine?.description,
    },
    {
      title: tiles?.activeLearning?.title,
      variant: "image",
      backgroundImage: breakpoints.isMobile ? gatsbyImages.RobotAiMobile : gatsbyImages.RobotAi,
      description: tiles?.activeLearning?.description,
      allFontsWhite: true,
      titleStyle: {
        backgroundColor: "rgba(0,0,0,0.6)",
        borderRadius: "4px",
        padding: "4px 8px",
        backdropFilter: "blur(20px)",
        width: "fit-content",

      },
      descriptionStyle: {
        backgroundColor: "rgba(0,0,0,0.6)",
        borderRadius: "4px",
        padding: "8px 8px",
        backdropFilter: "blur(20px)",
        width: "fit-content",
        maxWidth: "400px",
      },
      style: { minHeight: breakpoints.isMobile ? "470px" : "700px" },
      breakpoints: {
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12,
      },
    }
  ];

  return (
    <Container id="training">
      <TileSection
        title={content.title}
        titleColor={MAIN_COLOR}

      >
        {blocks.map((block, index) => (
          <Col
            lg={block.breakpoints.lg}
            md={block.breakpoints.md}
            sm={block.breakpoints.sm}
            xs={block.breakpoints.xs}
            key={index}
          >
            <TileBlock
              variant={block.variant}
              backgroundImage={block.backgroundImage}
              background={block.background}
              allFontsWhite={block.allFontsWhite}
              title={block.title}
              titleColor={block.titleColor}
              description={block.description}
              withBoxShadow={block.withBoxShadow}
              quote={block.quote}
              icon={block.icon}
              image={block.image}
              imagePosition={block.imagePosition}
              style={block.style}
              titleStyle={block.titleStyle}
              descriptionStyle={block.descriptionStyle}
            />
          </Col>
        ))}
      </TileSection>
    </Container>
  );
}

export default TrainingSection;
