//LIBRARIES
import React, { useState, } from "react";

import { useTranslation } from "gatsby-plugin-react-i18next";
import * as S from "./style";
import { StaticImage } from "gatsby-plugin-image";




function StartYourJourney() {
  // const { ...other } = props;
  const { t } = useTranslation();
  const content = t("How it works", { returnObjects: true });
  const [entered, setEntered] = useState(false);
  const [progress, setProgress] = useState(0);


  return (
    <S.Container id="start-your-ai-journey">
      {/* <div className="gatsby-image-wrapper">
      <StaticImage
        src="./wave-dots.jpg"
        alt="A Robot"
        placeholder="blurred"
        style={{
          position: "absolute",
          // width: "100%",
          width:"100%",
          //  zIndex: '-1',
          // minWidth: "100%",
          minWidth: "1000px",
          left: "50%",
          top: "50%",
          opacity: 1,
          transform: "translate(-50%,-40%)",
        }}
      />
      </div>
      */}
      <StaticImage
        src="./wave-dots.jpg"
        alt="A Robot"
        placeholder="blurred"
        style={{
          position: "absolute",
          // width: "100%",
          width: "100%",
          zIndex: '-1',
          // minWidth: "100%",
          minWidth: "1000px",
          left: "50%",
          top: "50%",
          opacity: 1,
          transform: "translate(-50%,-40%)",
        }}
      />
      <S.Background>
        <div className="background" />
      </S.Background>
    </S.Container>
    //  </Parallax>
  );
}

export default StartYourJourney;
