import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import ContactForm from "./ContactForm";

const Contact = ({ title  }) => {

    const { t } = useTranslation();

    function handleSubmit(e){
        e.preventDefault();
    }

    const content = t("Contact", { returnObjects: true });

    return <ContactForm id="contact" content={content}  handleSubmit={handleSubmit}/>
}

export default Contact;
