import styled, { keyframes } from "styled-components";
import { Skeleton } from "components/UI";
import BaseIcon from "icons";

const SCALE = [0.7, 0.9, 1.15];

const borderRadius = "15px";
// const tfunction = "cubic-bezier(0.19, 1, 0.22, 1)";
const tfunction = "cubic-bezier(0.19, 1, 0.22, 1)";
const ttime = "700ms";

export const StyledSkeleton = styled(Skeleton)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
`;

const TITLE_HEIGHT = 53;

export const StyledTitle = styled.h2`
  font-size: 0.7rem;
  font-weight: 350;
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.5em;
  height: ${TITLE_HEIGHT}px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  line-height: 0.8rem;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}px) and (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    font-size: 0.6rem;
    height: ${TITLE_HEIGHT*SCALE[1]}px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}px) and (max-width: ${(props) => props.theme.breakpoints.md}px) {
    font-size: 0.48rem;
    height: ${TITLE_HEIGHT*SCALE[0]}px;
  }
`;

export const StyledBlurriedImageContainer = styled.div`
  position: absolute;
  background-image: url(${(props) => props.img});
  /* transform: scale(0.2); */

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  -webkit-transform: scale(0.85, 0.8) translateY(15%) translate3d(0, 0, 0);
  transform: scale(0.85, 0.8) translateY(15%);

  :not(:root:root) {
    transform: scale(0.9, 0.8) translateY(25%) translateZ(0);
  }

  filter: blur(20px);
  -webkit-filter: blur(20px);
  -moz-filter: blur(20px);
`;

export const StyledBackdropFilterImgContainer = styled.div`
  @supports (-moz-appearance: none) {
    display: block;
  }

  position: absolute;
  /* transform: scale(1.3); */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  /* clip-path: polygon(100% 100%, 100% 70%, 0% 70%, 0% 100%); */
  /* clip-path: polygon(100% 100%, 100% 70%, 0% 70%, 0% 100%); */
  clip-path: polygon(100% 100%, 100% ${props=>props.height - TITLE_HEIGHT}px, 0% ${props=>props.height - TITLE_HEIGHT}px, 0% 100%);

  /* @media (min-width: 740px) and (max-width: 1400px) {
    clip-path: polygon(100% 100%, 100% ${props=>props.height - TITLE_HEIGHT*0.8}px, 0% ${props=>props.height - TITLE_HEIGHT*0.8}px, 0% 100%);
  }

  @media (min-width: 740px) and (max-width: 1100px) {
    clip-path: polygon(100% 100%, 100% ${props=>props.height - TITLE_HEIGHT*0.6}px, 0% ${props=>props.height - TITLE_HEIGHT*0.6}px, 0% 100%);
  } */


  @media (min-width: ${(props) => props.theme.breakpoints.sm}px) and (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    clip-path: polygon(100% 100%, 100% ${props=>props.height - TITLE_HEIGHT*SCALE[1]}px, 0% ${props=>props.height - TITLE_HEIGHT*SCALE[1]}px, 0% 100%);
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}px) and (max-width: ${(props) => props.theme.breakpoints.md}px) {
    clip-path: polygon(100% 100%, 100% ${props=>props.height - TITLE_HEIGHT*SCALE[0]}px, 0% ${props=>props.height - TITLE_HEIGHT*SCALE[0]}px, 0% 100%);
  }



  transition: clip-path ${ttime} ${tfunction};
  overflow: hidden;
/* 
  backdrop-filter: blur(10px); */

  /* > * {
    visibility: hidden;
  }


  

  @-moz-document url-prefix() {
    > * {
      visibility: visible;
      filter:  brightness(60%);
    }
    filter: blur(10px);
    backdrop-filter: none;
  } */

  > * {
      visibility: visible;
      filter:  brightness(60%);

    }
    filter: blur(4px);
    backdrop-filter: none;

  /* filter: brightness(50%); */


  
`;

const shine = keyframes`
  0% { background-position: 0;}
  60% { background-position: 180px;}
  100% { background-position: 250px;}
`;

export const StyledTopDiv = styled.div`
  width: 100%;
  height: 20px;
  top: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);

  /* display: flex;
  justify-content: center;
  color:white;
  align-items: center;
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  font-size: ${(props) => props.theme.fonts.title.xs.size};
  letter-spacing: 0.2em; */

  transition: transform ${ttime} ${tfunction};
`;

export const StyledTopDivText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateZ(0);
  background: linear-gradient(to right, #c9c9c9 0, white 10%, #c9c9c9 20%);
  background-position: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: ${shine} 3s infinite linear;

  @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    animation: none;
  }

  /* animation: ${shine} 3s infinite linear; */
  display: block;
  font-weight: ${(props) => props.theme.fonts.weight.medium};
  letter-spacing: 0.3em;
  font-size: 10px;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  text-decoration: none;
  white-space: nowrap;
`;

export const StyledImageContainer = styled.div`
  position: absolute;

  /* transform: scale(0.2); */
  top: auto;
  left: auto;
  width: 100%;
  height: 100%;
  text-align: center;
  object-fit: cover;
  transition: transform ${ttime} ${tfunction}, opacity 1s;
  opacity: ${props=> props.detected ? 1:0};
`;


export const StyledDescription = styled.p`
  font-size: 1.15rem;
  font-style: regular;
  color: white;
  opacity: 0;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  transition: opacity ${ttime} ${tfunction};

  /* @media (min-width: 740px) and (max-width: 1100px) {
    font-size: 0.6rem;
  }

  @media (min-width: 740px) and (max-width: 1400px) {
    padding: 0px 5px 5px;
  } */


  @media (min-width: ${(props) => props.theme.breakpoints.sm}px) and (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    font-size: 0.9rem;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}px) and (max-width: ${(props) => props.theme.breakpoints.md}px) {
    padding: 0px 10px 10px;
    font-size: 0.75rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px){
    font-size: 0.9rem;
  }


`;

// box-sizing: border-box;
// border: 1px solid grey;

export const StyledCard = styled.div`
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);

  backface-visibility: hidden;
  perspective: 1000;
  transform: translate3d(0, 0, 0);
  transform: translateZ(0);

  position: relative;

  display: flex;

  border-radius: ${borderRadius};
  height: 100%;
  width: 100%;
  align-items: flex-end;
  overflow: hidden;
  text-align: center;

  /* background-image: url(${(props) => props.img});
  background-size: cover; */
`;

export const StyledContent = styled.div`
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000;
  transform: translate3d(0, 0, 0);
  transform: translateZ(0);
  overflow: hidden;
  position: relative;
  /* padding: 14px; */
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  /* background-color: rgba(0, 0, 0, 0.2); */
  /* 
   -webkit-backdrop-filter: blur(5px);
   backdrop-filter: blur(5px); */
  border-radius: 0px 0px ${borderRadius} ${borderRadius};

  height: 100%;
  -webkit-transform: translateY(${props=>props.height - TITLE_HEIGHT}px) translateZ(0) translate3d(0, 0, 0);
  transform: translateY(${props=>props.height - TITLE_HEIGHT}px) translate3d(0, 0, 0) translate3d(0, 0, 0);

  @media (min-width: ${(props) => props.theme.breakpoints.sm}px) and (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    -webkit-transform: translateY(${props=>props.height - TITLE_HEIGHT*SCALE[1]}px) translateZ(0) translate3d(0, 0, 0);
  transform: translateY(${props=>props.height - TITLE_HEIGHT*SCALE[1]}px) translate3d(0, 0, 0) translate3d(0, 0, 0);
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}px) and (max-width: ${(props) => props.theme.breakpoints.md}px) {
    -webkit-transform: translateY(${props=>props.height - TITLE_HEIGHT*SCALE[0]}px) translateZ(0) translate3d(0, 0, 0);
    transform: translateY(${props=>props.height - TITLE_HEIGHT*SCALE[0]}px) translate3d(0, 0, 0) translate3d(0, 0, 0);
  }

  transition: transform ${ttime} ${tfunction};
`;

export const StyledContainer = styled.div`
  position: relative;
  /* height: 180px;
  width: 300px; */

  width:100%;
  height: 100%;
  cursor: ${(props) => (props.comingSoon ? "not-allowed" : "pointer")};
  perspective: 1000px;

 
 
  &:hover {
    ${StyledContent} {
      border-radius: ${borderRadius};
      transform: translateY(0px);
      background-color: rgba(0, 0, 0, 0.5);
      transition: transform ${ttime} ${tfunction};

    }

    ${StyledTitle} {
      height: 30px;
      margin-top: 8px;
    }

    
    ${StyledTopDiv} {
      transform: translateY(-30px) translateZ(0);
      transition: transform ${ttime} ${tfunction};
    }

    ${StyledTopDivText} {
      display: none;
    }

    ${StyledDescription} {
      opacity: 1;
      /* transition: opacity ${ttime} ${tfunction}; */
    }

    ${StyledBackdropFilterImgContainer} {
      clip-path: polygon(100% 100%, 100% 0%, 0% 0%, 0% 100%);
      /* clip-path: polygon(100% 100%, 100% 0%, 0% 0%, 0% 100%); */

      transition: clip-path ${ttime} ${tfunction};
    }
  }
`;


export const StyledLearnMoreContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 70px;
  height: 20px;
  margin:10px;
  border-radius: 15px;
  font-size: 0.5rem;
  font-weight: 700;
  text-transform: uppercase;
  background-color: rgba(0,0,0,0.2);
  backdrop-filter: blur(10px);
  color:rgba(255,255,255,0.65);
`;