import React, { useState } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useForm , useWatch} from "react-hook-form";
import { Input, Block } from "components/UI/Common/LandingPage";
import { toast } from "react-toastify";
import { Toast } from "components/UI";
import axios from "axios";
import {
  ContactContainer,
  FormGroup,
  Span,
  ButtonContainer,
  Row,
  Col,
  ContactButton
} from "./styles";



const ContactForm = ({ title, content, id }) => {
  const { register, handleSubmit, reset, formState: { errors, dirtyFields} } = useForm({mode: 'onChange'});
  const { t } = useTranslation();

  let inputs = [
    {name:"name", type:"text",  label:content.name},
    {name:"email", type:"email",  label:content.email},
    {name:"message", type:"textarea",  label:content.message}
  ];
  const [submitting, setSubmitting] = useState(false);

  function onSubmit(data) {
    console.log(data);
    setSubmitting(true);
    axios.post('https://api.theos.ai/v1/contact/save/', {name:data.name, email:data.email, message:data.message})
      .then((response) => {
        setSubmitting(false);
        toast.success(t("Contact success"));
        reset();
      })
      .catch((error) => {
        toast.error(t("Contact error"));
        setSubmitting(false);
      });
  }
 
  return (
    <ContactContainer id={id}>
      <Toast/>
      <Row justify="space-between">
        <Col lg={6} md={6} sm={12} xs={12}>
          <Block title={content.title} content={content.description} />
        </Col>
        <Col lg={6} md={6} sm={12} xs={12}>
          <FormGroup autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Col></Col>
            {inputs.map((input, index) => (
              <Col key={index}>
                <Input
                  name={input.name}
                  register={register}
                  type={input.type}
                  label={input.label}
                  error={errors[input.name]}
                  isDirty={dirtyFields[input.name]}
                />
              </Col>
            ))}
            <ButtonContainer>
              <ContactButton name="submit" type="submit" loading={submitting?1:undefined}>{t("Submit")}</ContactButton>
            </ButtonContainer>
          </FormGroup>
          {/* </Slide> */}
        </Col>
      </Row>
    </ContactContainer>
  );
};

export default ContactForm;
