import styled, { css, keyframes } from "styled-components";
import IconBase from "icons";

const hue = keyframes`

  0% {
    filter: drop-shadow(0px 0px 10px #4444dd) hue-rotate(0deg);
  }
  50% {
    filter: drop-shadow(0px 0px 10px #4444dd) hue-rotate(40deg);
  }
  100% {
    filter: drop-shadow(0px 0px 10px #4444dd) hue-rotate(0deg);
  }
`;


function createCSS() {
  let styles = '';
  for (let i = 0; i < 10; i += 1) {
     styles += `
      &:nth-child(${i + 1}) {
         filter: blur(0px);
         animation: blur-text 1s ${i/10}s infinite linear alternate;
      }
     `
  }
  return css`${styles}`;
}


export const LoadingContainer = styled.div`
  /* cursor: progress; */
  ${props => props.detecting ? 'opacity: 1;':'opacity: 0;'}


  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  border-radius: ${props => props.isSafari? '0px':'0px 8px'};

  background-color: rgba(10, 0, 40, 0.9);
  backdrop-filter: blur(16px);
  /* background-color: rgba(131, 52, 235,0.7); */
  position: absolute;
  width: 100%;
  height: 100%;
 
  z-index: 20;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition:  opacity 1s;

  @keyframes blur-text {
    60% {
      filter: blur(0px);
    }
    100% {
      filter: blur(4px);
    }
  }

  .loading {
    font-size: 20px;
  
    /* position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	z-index: 9999; */
  }

  .loading-text {
    width: 100%;
    height: 100px;
    line-height: 70px;
    span {
      display: inline-block;
      margin: 0 5px;
      color: #fff;
      font-family: "Quattrocento Sans", sans-serif;
      ${createCSS()};
    }
  }
`;

export const WandIcon = styled(IconBase).attrs((props) => ({
  name: "magic-wand",
  size: "40px",
}))`
  color: #fff;

  animation: ${hue} 2s linear infinite;

  text-shadow: 0 0 50px #0072ff, 0 0 100px #0072ff, 0 0 150px #0072ff,
    0 0 200px #0072ff;
`;
