//LIBRARIES
import React from 'react';
import DatasetSection from "./DatasetSection";
import TrainingSection from "./TrainingSection";
import DeploymentSection from "./DeploymentSection";
// import SubfieldsSection from "./SubfieldsSection";
import OutroSection from "./OutroSection";

  
function Sections(props) {
  const { children, name, ...other } = props;
  return (
    <>
      {/* <SubfieldsSection/> */}
      <DatasetSection/>
      <TrainingSection/>
      <DeploymentSection/>
      <OutroSection/>
    </>
  );
}


export default Sections;