import styled from "styled-components";
import {Button as BaseButton} from "components/UI";

export const Button = styled(BaseButton).attrs({
  variant: "contained",
})`
font-size: 20px;
padding: 8px 16px;
`;

export const RedButton = styled(BaseButton).attrs({
  variant: "contained",
})`
font-size: 16px;
padding: 8px 16px;
margin-top: 25px;
width: fit-content;

&& {
  background: rgb(224,9,136);
background: -moz-linear-gradient(145deg, rgba(224,9,136,1) 0%, rgba(218,38,51,1) 100%);
background: -webkit-linear-gradient(145deg, rgba(224,9,136,1) 0%, rgba(218,38,51,1) 100%);
background: linear-gradient(145deg, rgba(224,9,136,1) 0%, rgba(218,38,51,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e00988",endColorstr="#da2633",GradientType=1);
box-shadow: 2px 16px 36px rgba(224,9,136,0.4), 0px 1px 30px rgba(218,38,51,0.5);

:hover {
  box-shadow: 2px 4px 10px rgba(224,9,136,0.3), 0px 10px 10px rgba(218,38,51,0.2);
}

/* :hover {
  box-shadow: 2px 4px 5px rgba(224,9,136,0.4), 0px 10px 5px rgba(218,38,51,0.5);
} */
}

`;

export const DescriptionContainer = styled.div`
display: flex;
flex-direction: column;
`;




export const ButtonContainer = styled.div`
display: flex;
width: 100%;

justify-content: center;
padding: 64px 64px 0px;
`;


