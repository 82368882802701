//LIBRARIES
import React, { useState } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import * as S from "./style";
import { StaticImage } from "gatsby-plugin-image";
import { Parallax } from "react-scroll-parallax";
import Card from "./Card";

const SCALE = [0.7, 0.9, 1.15];

function StoriesSection(props) {
 
  const { t } = useTranslation();
  const content = t("stories", { returnObjects: true });
  const breakpoints = useBreakpoint();
  const [progress, setProgress] = useState(0);
  const ENTER_THRESHOLD = 0.45;

  const style = {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
  };

  const gatsbyImages = {
    cancer: {
      original: (
        <StaticImage
          src="../../../../assets/images/stories/cancer.jpg"
          alt="A Robot"
          placeholder="blurred"
          style={style}
        />
      ),
      detected: (
        <StaticImage
          src="../../../../assets/images/stories/cancer_detected.jpg"
          alt="A Robot"
          placeholder="blurred"
          style={style}
        />
      ),
      lowResolution: (
        <StaticImage
          src="../../../../assets/images/stories/cancer_detected.jpg"
          alt="A Robot"
          width={10}
          height={10}
          placeholder="blurred"
          style={style}
        />
      ),
    },
    mars: {
      original: (
        <StaticImage
          src="../../../../assets/images/stories/mars.jpg"
          alt="A Robot"
          placeholder="blurred"
          style={style}
        />
      ),
      detected: (
        <StaticImage
          src="../../../../assets/images/stories/mars_detected.jpg"
          alt="A Robot"
          placeholder="blurred"
          style={style}
        />
      ),
      lowResolution: (
        <StaticImage
          src="../../../../assets/images/stories/mars_detected.jpg"
          alt="A Robot"
          width={10}
          height={10}
          placeholder="blurred"
          style={style}
        />
      ),
    },
    robots: {
      original: (
        <StaticImage
          src="../../../../assets/images/stories/robot.jpeg"
          alt="A Robot"
          placeholder="blurred"
          style={style}
        />
      ),
      detected: (
        <StaticImage
          src="../../../../assets/images/stories/robot_detected.jpeg"
          alt="A Robot"
          placeholder="blurred"
          style={style}
        />
      ),
      lowResolution: (
        <StaticImage
          src="../../../../assets/images/stories/robot_detected.jpeg"
          alt="A Robot"
          width={10}
          height={10}
          placeholder="blurred"
          style={style}
        />
      ),
    },
    autopilot: {
      original: (
        <StaticImage
          src="../../../../assets/images/stories/autopilot.jpeg"
          alt="A Robot"
          placeholder="blurred"
          style={style}
        />
      ),
      detected: (
        <StaticImage
          src="../../../../assets/images/stories/autopilot_detected.jpeg"
          alt="A Robot"
          placeholder="blurred"
          style={style}
        />
      ),
      lowResolution: (
        <StaticImage
          src="../../../../assets/images/stories/autopilot_detected.jpeg"
          alt="A Robot"
          width={10}
          height={10}
          placeholder="blurred"
          style={style}
        />
      ),
    },
    intrusion: {
      original: (
        <StaticImage
          src="../../../../assets/images/stories/intrusion.jpeg"
          alt="A Robot"
          placeholder="blurred"
          style={style}
        />
      ),
      detected: (
        <StaticImage
          src="../../../../assets/images/stories/intrusion_detected.jpeg"
          alt="A Robot"
          placeholder="blurred"
          style={style}
        />
      ),
      lowResolution: (
        <StaticImage
          src="../../../../assets/images/stories/intrusion_detected.jpeg"
          alt="A Robot"
          width={10}
          height={10}
          placeholder="blurred"
          style={style}
        />
      ),
    },
    fire: {
      original: (
        <StaticImage
          src="../../../../assets/images/stories/fire.jpeg"
          alt="A Robot"
          placeholder="blurred"
          style={style}
        />
      ),
      detected: (
        <StaticImage
          src="../../../../assets/images/stories/fire_detected.jpeg"
          alt="A Robot"
          placeholder="blurred"
          style={style}
        />
      ),
      lowResolution: (
        <StaticImage
          src="../../../../assets/images/stories/fire_detected.jpeg"
          alt="A Robot"
          width={10}
          height={10}
          placeholder="blurred"
          style={style}
        />
      ),
    },
  };

  let scale = 1.15;
  scale = breakpoints.md ? SCALE[0] : breakpoints.lg ? SCALE[1] : SCALE[2];

  let cards = [
    {
      img: gatsbyImages.cancer.detected,
      imgOriginal: gatsbyImages.cancer.original,
      lowResolutionImg: gatsbyImages.cancer.lowResolution,
      title: content.cancer.title,
      description: content.cancer.description,
    },
    {
      img: gatsbyImages.fire.detected,
      imgOriginal: gatsbyImages.fire.original,
      lowResolutionImg: gatsbyImages.fire.lowResolution,
      title: content.fire.title,
      description: content.fire.description,
    },
    {
      img: gatsbyImages.autopilot.detected,
      imgOriginal: gatsbyImages.autopilot.original,
      lowResolutionImg: gatsbyImages.autopilot.lowResolution,
      title: content.autopilot.title,
      description: content.autopilot.description,
    },
    {
      img: gatsbyImages.robots.detected,
      imgOriginal: gatsbyImages.robots.original,
      lowResolutionImg: gatsbyImages.robots.lowResolution,
      title: content.robots.title,
      description: content.robots.description,
    },
    {
      img: gatsbyImages.mars.detected,
      imgOriginal: gatsbyImages.mars.original,
      lowResolutionImg: gatsbyImages.mars.lowResolution,
      title: content.mars.title,
      description: content.mars.description,
    },
    {
      img: gatsbyImages.intrusion.detected,
      imgOriginal: gatsbyImages.intrusion.original,
      lowResolutionImg: gatsbyImages.intrusion.lowResolution,
      title: content.intrusion.title,
      description: content.intrusion.description,
    },
  ];

  return (
    <Parallax
      onProgressChange={(progress) => setProgress(progress)}
      // onExit={() => setEntered(false)}
    >
      <S.Container id="stories">
        <S.TitleContainer scale={scale}>
          <S.Title>{content.title}</S.Title>
          <S.Subtitle>{content.subtitle}</S.Subtitle>
        </S.TitleContainer>

        {!breakpoints.sm ? (
          <S.CardsContainer scale={scale}>
            {cards.map((card, index) => (
              <div key={index} className={"story-" + (index + 1)}>
                <Card
                  detected={progress > ENTER_THRESHOLD ? true : false}
                  img={card.img}
                  imgOriginal={card.imgOriginal}
                  lowResolutionImg={card.lowResolutionImg}
                  title={card.title}
                  description={card.description}
                />
              </div>
            ))}
          </S.CardsContainer>
        ) : (
          <S.MobileCardsContainer scale={scale}>
            {cards.map((card, index) => (
              <div key={index} className={"story-" + (index + 1)}>
                <Card
                  detected={progress > ENTER_THRESHOLD ? true : false}
                  img={card.img}
                  imgOriginal={card.imgOriginal}
                  lowResolutionImg={card.lowResolutionImg}
                  title={card.title}
                  description={card.description}
                />
              </div>
            ))}
          </S.MobileCardsContainer>
        )}
      </S.Container>
    </Parallax>
  );
}

export default StoriesSection;
