import styled from "styled-components";
import { Divider as BaseDivider, RadialProgress as BaseRadialProgress, ParallaxCard as BaseParallaxCard } from "components/UI";
import hexToRgba from "hex-to-rgba";
import BaseIcon from "icons";

//rgb(47,131,189)
const BORDER_RADIUS = 32;

export const Container = styled.div`
 border-radius: ${BORDER_RADIUS}px;
`;

export const ParallaxCard = styled(BaseParallaxCard).attrs((props) => ({
  fixed: true,
  // height: 220,
  width: props.width,
  boxShadowColor: "#7a83ff",
  scale:2,
  borderRadius: BORDER_RADIUS,
}))`
width: 100%;
height: 100%;
`;

export const Title = styled.div`
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  font-size: ${(props) => props.theme.fonts.title.m.size};
  color: ${(props) => props.theme.colors.text.default};
  text-shadow: 0px 3px 6px #7a83ff;
  color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Info = styled.div`
  font-weight: ${(props) => props.theme.fonts.weight.medium};
  font-size: ${(props) => props.theme.fonts.title.xs.size};
  color: ${(props) => props.theme.colors.text.default};
  text-shadow: 0px 3px 6px #7a83ff;
  color: white;
  overflow:hidden; 
  white-space:nowrap; 
  text-overflow: ellipsis;
  margin-top:3px;
`;

export const LastTimeOnlineContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 0px;
`;

export const SecondaryInfo = styled.div`
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  font-size: ${(props) => props.theme.fonts.title.xs.size};
  color: ${(props) => props.theme.colors.text.default};
  text-shadow: 0px 3px 6px #7a83ff;
    margin-left: 8px;
  color: rgba(255,255,255,0.55);
  overflow:hidden; 
  white-space:nowrap; 
  text-overflow: ellipsis;
`;

export const Icon = styled(BaseIcon).attrs((props) => ({
  size: "40px"
}))`
  margin-top: 6px;
  height: 100px;
  width: 100px;
  /* filter: drop-shadow(0 5px 5px #7a83ff); */
  color: white;
`;

export const Divider = styled(BaseDivider)`
  height: 25px;
  opacity: 0.5;
`;

export const RadialProgress = styled(BaseRadialProgress).attrs((props) => ({
  size: 40,
  strokeWidth: 3,
}))``;

export const Storage = styled.div`
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  font-size: ${(props) => props.theme.fonts.title.s.size};
  color: ${(props) => props.theme.colors.text.default};

  display: flex;
  justify-content: space-around;
  align-items: center;

  text-shadow: 0px 3px 6px #7a83ff;

  .info {
    font-size: ${(props) => props.theme.fonts.title.xs.size};
    max-width: 50%;
    text-align: right;
  }

  color: white;
`;

export const HorizontalFlexbox = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top:11px;
  padding-bottom:11px;

  > :not(:first-child) {
    
    flex-grow: 1;
    flex-shrink: 1;
  }

  > :first-child {
  
    flex-grow: 2;
    flex-shrink: 1;
  }
`;

export const Base = styled.div`
  padding: 20px;
  width:  100%;
  height: 100%;

  background: rgba(150, 150, 150, 1);
  border-radius: 35px;
  /* box-shadow:
    inset 0 7em 10em -5em rgba(255,255,255,0.6),
    0 0.3em 0.5em -0.2em rgba(100,100,100,1),
    0 1em 2em -0.75em rgba(100,100,100,0.75),
    0 1em 3em -0.5em rgba(100,100,100,0.5),
    0 3em 3em -0.25em rgba(100,100,100,0.2); */
  transform: translate(-50%, -50%);
`;

export const Machine = styled(Base).attrs((props) => ({
  color: "#7a83ff",
  boxShadowColor: "#7a83ff",
}))`
  background: ${(props) => hexToRgba(props.color)};

  border-radius: ${BORDER_RADIUS}px;

  box-shadow:
    inset 0 7em 10em -5em rgba(255,255,255,0.6),
    0 0.3em 0.5em -0.2em ${(props) => hexToRgba(props.boxShadowColor, 1)},
    0 1em 2em -0.75em ${(props) => hexToRgba(props.boxShadowColor, 0.75)},
    0 1em 3em -0.5em ${(props) => hexToRgba(props.boxShadowColor, 0.5)},
    0 3em 3em -0.25em ${(props) => hexToRgba(props.boxShadowColor, 0.2)};

  box-shadow: ${(props) => props.selected && `inset 0 3em 3em -1em rgba(219, 214, 255, 0.35), inset 0px 0px 1px 3px rgba(255, 255, 255, 1), inset 3px 3px 10px 2px rgba(0, 0, 0, 0.5)`};
  display: flex;
  flex-direction: column;
transform: translateY(0px);
  transition: box-shadow 0.2s ease 0s;

`;

export const StatusDot = styled.div.attrs((props) => ({
  color: props.available ? props.theme.colors.text.success : props.theme.colors.text.error
}))`
  align-self: center;
  justify-content: center;
  width: ${props => props.available? '15px':'14px'};
  height: ${props => props.available? '15px':'14px'};
  border-radius: 12px;
  background-color: ${props => props.color};
  border: 3px solid rgba(6,111,184,0.3);
  box-shadow: 0px 0px 8px 0px ${(props) => props.color};
`;