import styled from "styled-components";

export const Container = styled.div`
overflow-x: hidden;
/* overflow-x: hidden;
overflow-y: scroll; */

 /* color: ${props => props.theme.colors.text.default}; */
`;



// export const Azul = styled(BaseIcon)`

// `;

